import { useNavigate } from 'react-router-dom';

import { IProduct } from '../../../../shared/interfaces/IProduct';

type ProductNameProps = {
  product: IProduct;
};

const ProductName = ({ product }: ProductNameProps): JSX.Element | null => {
  const navigate = useNavigate();

  const onProductNameClick = () => {
    const href = `/products/${product.id}/product-details`;

    navigate(href);
  };

  return (
    <div className='flex flex-column justify-content-center'>
      <div
        onClick={onProductNameClick}
        className={'font-normal max-w-fit hover:underline cursor-pointer'}
      >
        {product.product_name}
      </div>
    </div>
  );
};

export default ProductName;
