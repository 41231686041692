import { useTranslation } from 'react-i18next';

import GlobeIcon from '../../../../../../assets/icons/Globe';
import EmissionUnit from '../../../../../../components/emission-unit/EmissionUnit';
import { Locale } from '../../../../../../contexts/language/LanguageContext';
import {
  CountryCodeToLabelMap,
  RegionCodeToLabelMap,
} from '../../../../../../shared/helpers/country-region-helpers';
import { parseDateWithoutTime } from '../../../../../../shared/helpers/parse-date';
import { IProduct } from '../../../../../../shared/interfaces/IProduct';
import { DeclaredUnitToPlainSymbolsMap } from '../../../../../../shared/interfaces/IProductDetails';

type ProductDetailsTabProps = {
  product: IProduct;
  isLoading: boolean;
};

const ProductDetailsTab = ({ product }: ProductDetailsTabProps): JSX.Element => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const getLocationText = (): string => {
    if (product?.country) {
      return CountryCodeToLabelMap[product?.country] || product?.country;
    }

    if (product?.region) {
      return RegionCodeToLabelMap[product?.region] || product?.region;
    }

    return 'UNKNOWN';
  };

  const getLocationIcon = (): JSX.Element | null => {
    if (product?.country && CountryCodeToLabelMap[product.country]) {
      return (
        <span
          className={`fi fi-${product.country.toLowerCase()} border-round align-items-center justify-content-center`}
        />
      );
    }

    if (product?.region) {
      return <GlobeIcon />;
    }

    return null;
  };

  return (
    <div className='flex flex-column gap-6 mt-4 w-8'>
      {/* General Details */}
      <div className='flex flex-column gap-4'>
        <h4>
          {t('productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.title')}
        </h4>

        <div className='grid grid-nogutter'>
          {/* Product Id */}
          <div className='col-2 flex flex-column gap-2'>
            <span className='font-medium'>
              {t(
                'productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.productId',
              )}
            </span>
            <span className='font-light'>{product?.id}</span>
          </div>

          {/* Location */}
          <div className='col-2 flex flex-column gap-2'>
            <span className='font-medium'>
              {t(
                'productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.location',
              )}
            </span>

            <div className='flex flex-row gap-2 line-height-1 align-items-center font-light'>
              {getLocationIcon()}
              {getLocationText()}
            </div>
            <span />
          </div>
        </div>

        {/* Product Description */}
        <div className='flex flex-column gap-2'>
          <span className='font-medium'>
            {t(
              'productDetailsPage.tabs.productDetailsTab.sections.generalDetailsSection.productDescription',
            )}
          </span>
          <span className='font-light'>{product?.description}</span>
        </div>
      </div>

      {/* Active PCF */}
      <div className='flex flex-column gap-2'>
        <h4>{t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.title')}</h4>

        <div className='grid grid-nogutter'>
          {/* Pcf Id */}
          <div className='col flex flex-column gap-2'>
            <span className='font-medium'>
              {t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.pcfId')}
            </span>
            <span className='font-light'>TODO: pcf id here</span>
          </div>

          {/* Date Created */}
          <div className='col flex flex-column gap-2'>
            <span className='font-medium'>
              {t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.dateCreated')}
            </span>
            <span className='font-light'>
              {parseDateWithoutTime(
                product?.pcf?.active_carbon_footprint_created_date ?? '',
                language as Locale,
              ).toLocaleString()}
            </span>
          </div>

          {/* Declared Unit */}
          <div className='col flex flex-column gap-2'>
            <span className='font-medium'>
              {t(
                'productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.declaredUnit',
              )}
            </span>
            <span className='font-light flex flex-row gap-1'>
              <span>1</span>
              {product?.pcf?.active_carbon_footprint_declared_unit && (
                <span>
                  {
                    DeclaredUnitToPlainSymbolsMap[
                      product?.pcf?.active_carbon_footprint_declared_unit
                    ]
                  }
                </span>
              )}
            </span>
          </div>

          {/* Total */}
          <div className='col flex flex-column gap-2'>
            <span className='font-medium'>
              {t('productDetailsPage.tabs.productDetailsTab.sections.activePcfSection.total')}
            </span>

            {product?.pcf?.pcf_excluding_biogenic && (
              <EmissionUnit
                value={product?.pcf?.pcf_excluding_biogenic}
                unit={product?.pcf?.active_carbon_footprint_declared_unit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsTab;
