import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getFullCarbonContentText,
  getFullDeclaredUnitText,
  getFullEmissionText,
} from '../../../../../shared/helpers/textify-numerical-footprint-values-with-their-units';
import { IComponentDetails } from '../../../../../shared/interfaces/IProductDetails';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';

interface ProductCarbonFootprintSectionProps extends ComponentProps<'div'> {
  componentDetails?: IComponentDetails;
  isLoading?: boolean;
}

const ProductCarbonFootprintSection = (props: ProductCarbonFootprintSectionProps): JSX.Element => {
  const { componentDetails, isLoading, ...rest } = props;

  const { t } = useTranslation();

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>
        {t('componentDetailsPage.sections.productCarbonFootprintSection.productCarbonFootprint')}
      </h5>

      <InfoCardRow
        label={t('componentDetailsPage.sections.productCarbonFootprintSection.declaredUnit')}
      >
        {getFullDeclaredUnitText(
          componentDetails?.pcf.productMassPerDeclaredUnit,
          componentDetails?.pcf.declaredUnit,
        )}
      </InfoCardRow>

      <h6>{t('componentDetailsPage.sections.productCarbonFootprintSection.carbonContent')}</h6>
      <InfoCardRow
        label={t('componentDetailsPage.sections.productCarbonFootprintSection.fossilCarbonContent')}
      >
        {getFullCarbonContentText(
          componentDetails?.pcf.carbonContentTotal, // TODO: Just putting a flag here, this field is missing in JSON
          componentDetails?.pcf.declaredUnit,
        )}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.productCarbonFootprintSection.biogenicCarbonContent',
        )}
      >
        {getFullCarbonContentText(
          componentDetails?.pcf.carbonContentBiogenic,
          componentDetails?.pcf.declaredUnit,
        )}
      </InfoCardRow>

      <h6>{t('componentDetailsPage.sections.productCarbonFootprintSection.total')}</h6>
      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.productCarbonFootprintSection.pcfExclBiogenicEmissionsAndRemovals',
        )}
        textColor='text-primary-300'
      >
        {getFullEmissionText(
          componentDetails?.pcf.pcfExcludingBiogenic,
          componentDetails?.pcf.declaredUnit,
        )}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.productCarbonFootprintSection.pcfInclBiogenicEmissionsAndRemovals',
        )}
      >
        {getFullEmissionText(
          componentDetails?.pcf.pcfIncludingBiogenic,
          componentDetails?.pcf.declaredUnit,
        )}
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(ProductCarbonFootprintSection);
