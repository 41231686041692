import { classNames } from 'primereact/utils';

import SearchBar from '../../../components/search-bar/SearchBar';
import { RequestFilters } from './PcfRequestsTable';

type PcfRequestsTableHeaderProps = {
  requestFilters: RequestFilters;
  updateRequestFilters: (filters: RequestFilters) => void;
};

const PcfRequestsTableHeader = ({
  requestFilters,
  updateRequestFilters,
}: PcfRequestsTableHeaderProps) => {
  const onSearchChange = (value: string) => {
    updateRequestFilters({
      ...requestFilters,
      searchStr: value,
    });
  };

  return (
    <div className={classNames('flex justify-content-end align-items-center flex-wrap')}>
      <SearchBar onSearchChange={onSearchChange} initialValue={requestFilters.searchStr} />
    </div>
  );
};

export default PcfRequestsTableHeader;
