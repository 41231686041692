import { Button } from 'primereact/button';
import {
  Inplace,
  InplaceContent,
  InplaceDisplay,
  InplaceProps,
  InplaceToggleEvent,
} from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import { useInplaceEdit } from '../../../../contexts/component-details-page-inplace-edit/ComponentDetailsPageInplaceEditContext';
import styles from './InplaceEdit.module.css';

type InplaceEditProps = InplaceProps & {
  fieldId: string; // The unique identifier of the field, used for single field activation
  undefinedValue?: string; // The value to display when the value is not specified
  value?: string; // The value of the field
  placeholder?: string; // The placeholder of the input field
  disabled?: boolean; // Whether the field is disabled or not

  textClassname?: string; // The classname of the text, both in display and content(input field)
  className?: string; // The classname of the inplace edit container as a whole
  displayClassname?: string; // The classname of the display container (when the input field is not active, i.e. in closed state)
  contentClassname?: string; // The classname of the content container (when the input field is active, i.e. in open state)

  // The custom content to display when the value is not specified
  // Either use this or the undefinedValue prop
  customUndefinedDisplay?: JSX.Element;
};

const InplaceEdit = (props: InplaceEditProps) => {
  // Destructure the props
  const {
    fieldId,
    value: initialValue,
    undefinedValue,
    placeholder,
    disabled,

    textClassname = 'text-md',
    className,
    displayClassname,
    contentClassname,

    customUndefinedDisplay,
  } = props;

  // Destructure the inplace edit context
  const { componentId, activeFieldId, activate, deactivate, handleSave, isSaving } =
    useInplaceEdit();

  const { control, setValue } = useForm<{ value?: string }>({
    defaultValues: { value: initialValue },
  });

  const {
    field: { ref: inputRef, onChange, value: enteredValue },
  } = useController({
    name: 'value',
    control,
  });

  const [lastSetValue, setLastSetValue] = useState<string | undefined>(initialValue);

  const buttonGroupRef = useRef<HTMLDivElement>(null);
  const [buttonGroupWidth, setButtonGroupWidth] = useState(0);

  // Once the buttons are rendered, calculate their width and give that amount of right-padding to the input field
  // So that the buttons don't overlap the text in the input field
  useEffect(() => {
    if (buttonGroupRef.current && activeFieldId === fieldId) {
      const width = buttonGroupRef.current.offsetWidth;
      setButtonGroupWidth(width);
    }
  }, [buttonGroupRef, activeFieldId]);

  // Listen for rollback events (only when the field is active)
  useEffect(() => {
    if (activeFieldId === fieldId) {
      const handleRollback = () => {
        setValue('value', initialValue as string);
        deactivate();
      };
      window.addEventListener(`inplace-edit:${fieldId}:rollback`, handleRollback);

      // Cleanup the event listener
      return () => {
        window.removeEventListener(`inplace-edit:${fieldId}:rollback`, handleRollback);
      };
    }
  }, [activeFieldId]);

  // When the active field changes, check if the current field is the active field
  // If active field is not NULL and also not equal to the current field, this means user activated another field without clicking "Cancel" or "Save"
  // In this case, rollback the value to the lastSetValue
  useEffect(() => {
    if (activeFieldId && activeFieldId !== fieldId) {
      setValue('value', lastSetValue as string);
    }
  }, [activeFieldId]);

  const onToggle = (e: InplaceToggleEvent) => {
    if (e.value) {
      activate(fieldId);
    }
  };

  const onCancelClick = () => {
    // Rollback to the initial value and deactivate the field
    setValue('value', lastSetValue as string);
    deactivate();
  };

  const onSaveClick = () => {
    // Check if the enteredValue is the same as the given value
    if (enteredValue === lastSetValue) {
      setValue('value', lastSetValue as string);
      deactivate();

      return;
    }

    // Check if the enteredValue is empty, then send null to the API
    if (!enteredValue || enteredValue.trim() === '') {
      setValue('value', '');
      setLastSetValue('');
      handleSave(componentId ?? '', fieldId, null);

      return;
    }

    setValue('value', enteredValue);
    setLastSetValue(enteredValue);
    handleSave(componentId ?? '', fieldId, enteredValue);
  };

  // Variable to be used to disable/enable the save button
  const isSaveButtonDisabled = isSaving || enteredValue === lastSetValue;

  const displayText =
    !enteredValue || enteredValue === undefined || enteredValue === null
      ? customUndefinedDisplay ?? undefinedValue
      : enteredValue;

  return (
    <div className={classNames(styles['inplace-edit'], className)}>
      <Inplace active={activeFieldId === fieldId} onToggle={onToggle} disabled={disabled}>
        {/* The component to show when input field is closed */}
        <InplaceDisplay>
          <div
            className={classNames(
              'flex w-full border-m border-1 border-transparent py-2 border-round-md text-gray-500 font-italic line-height-2',
              styles['inplace-edit-display-container'],
              displayClassname,
              textClassname,
              {
                'cursor-auto': isSaving,
                'cursor-pointer': !isSaving,
              },
            )}
          >
            {displayText}
          </div>
        </InplaceDisplay>

        {/* The component to show when input field is opened */}
        <InplaceContent>
          <div
            className={classNames(
              'flex w-full align-items-center gap-2 relative',
              contentClassname,
            )}
          >
            <InputText
              ref={inputRef}
              value={enteredValue}
              placeholder={placeholder}
              autoFocus
              disabled={isSaving}
              onChange={onChange}
              onKeyDown={(e) => {
                if (isSaving) return;

                if (e.key === 'Enter') {
                  if (isSaveButtonDisabled) return;

                  onSaveClick();
                } else if (e.key === 'Escape') {
                  onCancelClick();
                }
              }}
              className={classNames('w-full pl-2 py-2 line-height-2', textClassname)}
              style={{ paddingRight: `${buttonGroupWidth}px` }} // Dynamically set the right padding, based on the buttons' width
            />
            <div
              className={classNames(
                'flex gap-2 absolute right-0 justify-content-center align-items-center px-2 py-2',
                styles['input-field-button-group'],
              )}
              ref={buttonGroupRef}
            >
              <Button
                icon='pi pi-check'
                disabled={isSaveButtonDisabled}
                loading={isSaving}
                onClick={onSaveClick}
              />
              <Button
                icon='pi pi-times'
                className='bg-gray-600'
                disabled={isSaving}
                onClick={onCancelClick}
              />
            </div>
          </div>
        </InplaceContent>
      </Inplace>
    </div>
  );
};

export default InplaceEdit;
