import EmissionUnit from '../../../components/emission-unit/EmissionUnit';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { IComponent } from '../../../shared/interfaces/IComponent';

type ComponentPcfValueProps = {
  component: IComponent;
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
};

const ComponentPcfValue = ({
  component,
  biogenicEmissionsMode,
}: ComponentPcfValueProps): JSX.Element | null => {
  if (biogenicEmissionsMode === PCFBiogenicEmissionsMode.Include && component.pcf_including) {
    return <EmissionUnit value={component.pcf_including} />;
  } else if (
    biogenicEmissionsMode === PCFBiogenicEmissionsMode.Exclude &&
    component.pcf_excluding
  ) {
    return <EmissionUnit value={component.pcf_excluding} />;
  }

  return null;
};

export default ComponentPcfValue;
