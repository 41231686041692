import {
  SUFFIX_EMISSION,
  SUFFIX_EMISSION_WITHOUT_UNIT,
} from '../../shared/constants/products.constant';
import {
  DeclaredUnit,
  DeclaredUnitToPlainSymbolsMap,
} from '../../shared/interfaces/IProductDetails';

type EmissionUnitProps = {
  value: string | number;
  unit?: DeclaredUnit;
};

export default function EmissionUnit({ value, unit }: EmissionUnitProps) {
  if (unit) {
    return (
      <div className='flex flex-row align-items-center'>
        <span className='mr-1 text-lg font-semibold'>{value}</span>
        <div>
          {SUFFIX_EMISSION_WITHOUT_UNIT}/{DeclaredUnitToPlainSymbolsMap[unit]}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <span className='mr-1 text-lg font-semibold'>{value}</span>
        {SUFFIX_EMISSION}
      </>
    );
  }
}
