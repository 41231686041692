/* eslint-disable camelcase */
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import EmptyProductsImg from '../../../../assets/images/empty-products.svg';
import EmissionUnit from '../../../../components/emission-unit/EmissionUnit';
import EmptyScreen from '../../../../components/empty-screen/EmptyScreen';
import { GetProductsProps, useGetProducts } from '../../../../hooks/api/product';
import useDialogContext from '../../../../hooks/dialog/useDialogContext';
import {
  CountryCodeToLabelMap,
  RegionCodeToLabelMap,
} from '../../../../shared/helpers/country-region-helpers';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import {
  productsTableLocationColumnSortFunction,
  productsTablePcfColumnSortFunction,
  productsTableProductIdColumnSortFunction,
} from '../../helpers/tableColumnSortFunctions';
import { IProductFilters } from '../../interfaces/IProductFilters';
import ProductName from '../product-name/ProducName';
import ProductsHeader from '../products-header/ProductsHeader';

const ProductsTable = (): JSX.Element => {
  // Search params (filters) from the URL
  const [searchParams, setSearchParams] = useSearchParams();

  // Parameters used for filtering when fetching products
  const [productFilters, setProductFilters] = useState<IProductFilters>({
    countryFilter: searchParams.get('country')?.split(',') || [],
    regionFilter: searchParams.get('region')?.split(',') || [],
    searchStr: searchParams.get('search') || '',
  });

  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  // Function for updating the URL string parameters (filters)
  const updateProductFilters = (newFilters: IProductFilters) => {
    let newSearchParams: GetProductsProps = {};

    if (newFilters.countryFilter.length) {
      newSearchParams = { ...newSearchParams, country: newFilters.countryFilter.join(',') };
    }

    if (newFilters.regionFilter.length) {
      newSearchParams = { ...newSearchParams, region: newFilters.regionFilter.join(',') };
    }

    if (newFilters.searchStr) {
      newSearchParams = { ...newSearchParams, search: newFilters.searchStr };
    }

    // Update URL parameters
    setSearchParams(newSearchParams);
  };

  // Listen to URL string parameter changes, and update the filter values
  useEffect(() => {
    setProductFilters({
      countryFilter: searchParams.get('country')?.split(',') || [],
      regionFilter: searchParams.get('region')?.split(',') || [],
      searchStr: searchParams.get('search')?.trim() || '',
    });
  }, [searchParams]);

  // Prepare the filter parameters for fetching products, utilizing memoization
  const filterParams = useMemo(() => {
    const paramsValue: GetProductsProps = {};
    if (productFilters.countryFilter.length) {
      paramsValue['country'] = productFilters.countryFilter.join(',');
    }

    if (productFilters.regionFilter.length) {
      paramsValue['region'] = productFilters.regionFilter.join(',');
    }

    if (productFilters.searchStr) {
      paramsValue['search'] = productFilters.searchStr.trim();
    }

    return paramsValue;
  }, [productFilters]);

  // RQ Query to get products
  const { data: products, isLoading } = useGetProducts(filterParams);

  const productNameTemplate = (rowData: IProduct): JSX.Element => {
    return <ProductName product={rowData} />;
  };

  const locationTemplate = (rowData: IProduct): string | null => {
    if (rowData.country) {
      return CountryCodeToLabelMap[rowData.country] || rowData.country;
    } else if (rowData.region) {
      return RegionCodeToLabelMap[rowData.region] || rowData.region;
    }

    return '';
  };

  const pcfTemplate = (rowData: IProduct): JSX.Element | string => {
    const emissionValue = rowData?.pcf?.pcf_excluding_biogenic || 0;

    if (!emissionValue) {
      return 'N/A';
    }

    return (
      <EmissionUnit
        value={emissionValue}
        unit={rowData?.pcf?.active_carbon_footprint_declared_unit}
      />
    );
  };

  // If no data, show the empty screen
  if (!isLoading && products?.length === 0 && Object.keys(filterParams).length === 0) {
    return (
      <EmptyScreen imageSrc={EmptyProductsImg}>
        <div className='flex flex-column align-items-center p-4 gap-3'>
          <h4 className='m-0 p-0'>{t('productsPage.emptyScreen.title')}</h4>
          <span>{t('productsPage.emptyScreen.text')}</span>
          <Button
            label={t('productsPage.emptyScreen.buttons.addProduct')}
            onClick={() => {
              openDialog('add-product-dialog');
            }}
          />
        </div>
      </EmptyScreen>
    );
  }

  const pcfColumnHeaderTemplate = (): JSX.Element => {
    return (
      <div className='flex flex-column'>
        <span>{t('productsPage.productsTable.columns.pcf.header1')}</span>
        <span className='text-sm font-light'>
          {t('productsPage.productsTable.columns.pcf.header2')}
        </span>
      </div>
    );
  };

  return (
    <div>
      <DataTable
        value={products}
        loading={isLoading}
        header={
          <ProductsHeader
            productFilters={productFilters}
            updateProductFilters={updateProductFilters}
          />
        }
        scrollable
        scrollHeight='600px'
      >
        <Column
          header={t('productsPage.productsTable.columns.productName')}
          body={productNameTemplate}
          sortField='product_name'
          sortable
          style={{ width: '20rem' }}
        />
        <Column
          field='product_id'
          header={t('productsPage.productsTable.columns.productId')}
          sortable
          sortFunction={productsTableProductIdColumnSortFunction}
          style={{ width: '20rem' }}
        />
        <Column
          header={t('productsPage.productsTable.columns.location')}
          body={locationTemplate}
          sortable
          field='region' // Specifying a field to enable sorting, although we rely on both country and region fields to render the location
          sortFunction={productsTableLocationColumnSortFunction}
          style={{ width: '20rem' }}
        />
        <Column
          header={pcfColumnHeaderTemplate}
          body={pcfTemplate}
          sortable
          field='pcf_excluding_biogenic'
          sortFunction={productsTablePcfColumnSortFunction}
          style={{ width: '20rem' }}
        />
      </DataTable>

      <Button
        className='mr-auto mt-4 px-3'
        label={t('productsPage.buttons.addProduct')}
        onClick={() => {
          openDialog('add-product-dialog');
        }}
      />
    </div>
  );
};

export default ProductsTable;
