/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Skeleton } from 'primereact/skeleton';
import { Navigate, useParams } from 'react-router-dom';

import { useGetProductById } from '../../hooks/api/product';
import ProductBreadCrumb from './components/product-breadcrumb/ProductBreadCrumb';
import ProductTabView from './components/product-tab-view/ProductTabView';

const ProductDetailsPage = (): JSX.Element => {
  const { productId } = useParams() as { productId: string };

  const { data: product, isLoading } = useGetProductById(productId, {
    enabled: !!productId,
    retry: 1,
  });

  if (!product && !isLoading) {
    return <Navigate to='/404' />;
  }

  return (
    <div className={'px-6 py-5 flex flex-column gap-5'}>
      {/* Breadcrumb */}
      <ProductBreadCrumb isLoading={isLoading} productName={product?.product_name} />

      {/* Product Name */}
      {isLoading ? (
        <Skeleton height='29.39px' width='25%' />
      ) : (
        <h1 className='text-2xl md:text-4xl p-0 m-0'>{product?.product_name}</h1>
      )}

      {/* Tabs */}
      {isLoading ? (
        <Skeleton height='45px' width='30%' className='mt-4' />
      ) : (
        <ProductTabView product={product!} isLoading={isLoading} />
      )}
    </div>
  );
};

export default ProductDetailsPage;
