import { getArrayFromObject } from '../helpers/get-array-from-object';

export enum PCFRequestStatus {
  Open = 'open',
  Pending = 'pending',
  Complete = 'complete',
  Rejected = 'rejected',
  Outdated = 'outdated',
  Updated = 'updated',
}

export const PCFRequestStatusMap = {
  [PCFRequestStatus.Open]: 'Open',
  [PCFRequestStatus.Pending]: 'Pending',
  [PCFRequestStatus.Complete]: 'Complete',
  [PCFRequestStatus.Rejected]: 'Declined',
  [PCFRequestStatus.Outdated]: 'Expired',
  [PCFRequestStatus.Updated]: 'Updated',
};

export const PCFRequestStatusList = getArrayFromObject(PCFRequestStatusMap);

export enum PCFRequestRegion {
  AsiaPacific = 'A',
  SouthAmerica = 'SA',
  NorthAmerica = 'N',
  Europe = 'E',
}

export const PCFRequestRegionMap = {
  [PCFRequestRegion.AsiaPacific]: 'Asia Pacific',
  [PCFRequestRegion.SouthAmerica]: 'South America',
  [PCFRequestRegion.NorthAmerica]: 'North America',
  [PCFRequestRegion.Europe]: 'Europe',
};

export const PCFRequestRegionList = getArrayFromObject(PCFRequestRegionMap);

export enum PCFRequestRegionType {
  Global = 'global',
  Regional = 'region',
}
