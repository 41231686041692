import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import SearchBar from '../../../../components/search-bar/SearchBar';
import { IProductFilters } from '../../interfaces/IProductFilters';
import LocationFilter from '../location-filter/LocationFilter';

type ProductsHeaderProps = {
  productFilters: IProductFilters;
  updateProductFilters: (filters: IProductFilters) => void;
};

const ProductsHeader = ({
  productFilters,
  updateProductFilters,
}: ProductsHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  const onSearchChange = (value: string) => {
    updateProductFilters({
      ...productFilters,
      searchStr: value,
    });
  };

  return (
    <div
      className={classNames(
        'flex flex-column md:flex-row w-full justify-content-end align-items-center gap-2',
      )}
    >
      <label className='hidden xl:block'>{t('productsPage.filterText')} </label>

      <LocationFilter productFilters={productFilters} updateProductFilters={updateProductFilters} />
      <SearchBar onSearchChange={onSearchChange} initialValue={productFilters.searchStr} />
    </div>
  );
};

export default ProductsHeader;
