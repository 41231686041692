import { useTranslation } from 'react-i18next';

import { mapComponentRegionToKey } from '../../enums/component-region';
import { IComponent } from '../../interfaces/IComponent';

type ComponentRegionProps = {
  component: IComponent;
};

const ComponentRegion = ({ component }: ComponentRegionProps) => {
  const { t } = useTranslation();

  if (!component.region) return;

  return t(`componentsPage.regionNames.${mapComponentRegionToKey(component.region)}`);
};

export default ComponentRegion;
