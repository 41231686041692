import { TabPanel, TabView, TabViewProps, TabViewTabChangeEvent } from 'primereact/tabview';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IProduct } from '../../../../shared/interfaces/IProduct';
import styles from './ProductTabView.module.css';
import PcfTab from './tabs/pcf-tab/PcfTab';
import PermissionsTab from './tabs/permissions-tab/PermissionsTab';
import ProductDetailsTab from './tabs/product-details-tab/ProductDetailsTab';

interface ProductTabViewProps extends TabViewProps {
  product: IProduct;
  isLoading: boolean;
}

const tabIndexMapping = {
  0: 'product-details',
  1: 'pcf',
  2: 'permissions',
};

const ProductTabView = (props: ProductTabViewProps) => {
  const { product, isLoading, ...restProps } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  if (!product) {
    return <></>;
  }

  const handleTabChange = (event: TabViewTabChangeEvent) => {
    const href = `/products/${product?.id}/${
      tabIndexMapping[event.index as keyof typeof tabIndexMapping]
    }`;
    navigate(href);
    setActiveTabIndex(event.index);
  };

  return (
    <TabView
      className={styles['product-tab-view']}
      pt={{
        nav: { className: 'flex flex-row gap-6' },
      }}
      {...restProps}
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
    >
      {/* Product Details Tab */}
      <TabPanel header={t('productDetailsPage.tabs.productDetailsTab.tabHeader')}>
        <ProductDetailsTab product={product} isLoading={isLoading} />
      </TabPanel>

      {/* PCF Tab */}
      <TabPanel header={t('productDetailsPage.tabs.pcfTab.tabHeader')}>
        <PcfTab product={product} />
      </TabPanel>

      {/* Permisions */}
      <TabPanel header={t('productDetailsPage.tabs.permissionsTab.tabHeader')}>
        <PermissionsTab />
      </TabPanel>
    </TabView>
  );
};

export default ProductTabView;
