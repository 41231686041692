import { TreeNode } from 'primereact/treenode';

import GlobeIcon from '../../assets/icons/Globe';

/**
 * Recursively prepares and assigns flag icons to tree nodes.
 *
 * This function traverses through a list of tree nodes and assigns a flag icon
 * to each node based on its key. If a node has children, it assigns a globe icon
 * instead and recursively processes its children.
 *
 * @param nodes - An array of tree nodes to process.
 * @returns void
 */
export const prepareFlagIconsRecursively = (nodes: TreeNode[]) => {
  nodes.forEach((node) => {
    if (!node.children) {
      node.icon = (
        <span
          className={`fi fi-${(
            node.key as string
          ).toLowerCase()} border-round align-items-center justify-content-center mr-2`}
        />
      );
    } else {
      node.icon = (
        <div className='mr-1 flex justify-content-center align-items-center'>
          <GlobeIcon />
        </div>
      );
    }

    if (node.children) {
      prepareFlagIconsRecursively(node.children);
    }
  });
};
