import { createContext } from 'react';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';

import { IComponent } from '../../shared/interfaces/IComponent';

export type IWebsocketContext = Pick<
  WebSocketHook,
  'lastMessage' | 'lastJsonMessage' | 'readyState'
> & {
  useRegisterCallback: (
    eventType: WebsocketEventType,
    callback: (message: IWebsocketMessage) => void,
  ) => void;
};

export enum WebsocketEventType {
  NewNotification = 'new_notification',
  RequestPCF = 'request_pcf',
  RequestBulkPcfSingleCompleted = 'request_bulk_pcf',
  RequestBulkPcfSummary = 'request_bulk_pcf_summary',
}

export enum ModuleType {
  COMPANIES = 'companies',
  PRODUCTS = 'products',
  USERS = 'users',
  REQUEST = 'request',
}

export interface IWebsocketMessage {
  user_id: number;
  eventType: WebsocketEventType;
  success?: boolean;
  message?: string;
  error?: string;
  componentName?: string;
  type?: ModuleType;

  // When eventType is request_bulk_pcf_summary (summary of bulk pcf)
  failureCount?: number;
  successCount?: number;

  // When eventType is request_bulk_pcf (result of an individual component in bulk pcf)
  component?: IComponent;
}

const WebsocketContext = createContext({} as IWebsocketContext);

export default WebsocketContext;
