import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import useDialogContext from '../../../hooks/dialog/useDialogContext';

type ComponentNameProps = {
  componentId?: number;
  componentName?: string;
  ownProductName?: string;
  sustainableAlternativeAvailable?: boolean;
  href?: string;
};

const ComponentName = ({
  componentId,
  componentName,
  ownProductName,
  sustainableAlternativeAvailable,
  href,
}: ComponentNameProps): JSX.Element | null => {
  if (!componentName) {
    return null;
  }

  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  const secondaryComponentName = ownProductName ?? null;

  const navigate = useNavigate();
  const location = useLocation();

  const onProductNameClick = () => {
    if (href) {
      const pathname = location.pathname;
      const search = location.search;

      navigate(href, { state: { from: `${pathname}${search}` } });
    }
  };

  const onCheckForSustainableAlternativeClick = () => {
    openDialog('check-for-sustainable-alternative-dialog', { componentId: componentId as number });
  };

  return (
    <div className='flex flex-column justify-content-center'>
      <div
        onClick={onProductNameClick}
        className={classNames('font-normal max-w-fit', {
          'cursor-pointer': !!href,
          'hover:underline': !!href,
        })}
      >
        {componentName}
      </div>

      {secondaryComponentName && (
        <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600 line-height-2'>
          {secondaryComponentName}
        </div>
      )}

      {!!sustainableAlternativeAvailable && (
        <div
          className='flex max-w-fit flex-row hover:underline cursor-pointer text-secondary'
          onClick={onCheckForSustainableAlternativeClick}
        >
          <i className='cb cb-icon-leaf secondary-icon' />

          <div className='line-height-2'>{t('componentsPage.checkForSustainableAlternative')}</div>
        </div>
      )}
    </div>
  );
};

export default ComponentName;
