import { useQueryClient } from '@tanstack/react-query';
import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AmplifyConfig from '../../config/auth';
import { CognitoErrorCode, CognitoErrorMessage } from '../../shared/enums/cognito-error-code';
import { LoginErrorCode } from '../../shared/enums/login-error-code';
import { User } from '../../shared/types/User';
import { useGetUser } from '../api/user';

Amplify.configure({
  ...AmplifyConfig,
  storage: window.sessionStorage.length ? sessionStorage : localStorage,
});

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User | null;
  signIn: (username: string, password: string, staySignedIn: boolean) => Promise<Result>;
  signOut: () => Promise<{ success: boolean; message: string }>;
  completeNewPassword: (
    username: string,
    oldPassowrd: string,
    newPassword: string,
  ) => Promise<Result>;
  sendVerificationCode: (username: string) => Promise<{ success: boolean; message: string }>;
  confirmPassword: (
    username: string,
    confirmCode: string,
    newPassword: string,
  ) => Promise<{ success: boolean; message: string }>;
}

interface Result {
  success: boolean;
  message: string;
  errorCode?: LoginErrorCode;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useAuthProvider = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const { t } = useTranslation();

  const { refetch: fetchMyData } = useGetUser();

  const queryClient = useQueryClient();

  useEffect(() => {
    async function fetchUser() {
      try {
        const result = await Auth.currentAuthenticatedUser();

        // If authentication check is sucessfull, then fetch user data from API
        const { data: userData } = await fetchMyData();

        setUser({
          id: userData?.id,
          username: result.username,
          email: userData?.email ?? '',
          givenName: userData?.first_name ?? '',
          familyName: userData?.last_name ?? '',
          company: {
            id: userData?.company.id ?? 0,
            company_id: userData?.company.company_id ?? '', // eslint-disable-line camelcase
            company_name: userData?.company.company_name ?? '', // eslint-disable-line camelcase
            edcConfigurationStatus: userData?.company?.edc_configuration_status,
            license: userData?.company?.license || 'basic',
          },
          role: result?.attributes?.['custom:role'],
        });

        setIsAuthenticated(true);
        setIsLoading(false);
      } catch (error) {
        setUser(null);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    }

    fetchUser();
  }, []);

  const signIn = async (username: string, password: string, staySignedIn: boolean) => {
    try {
      Amplify.configure({
        storage: staySignedIn ? window.localStorage : window.sessionStorage,
      });

      const result = await Auth.signIn(username, password);
      const userGroups = result.signInUserSession.idToken.payload['cognito:groups'];

      // If user is super-admin, then logout
      if (userGroups?.includes('super-admin')) {
        await Auth.signOut();

        return {
          success: false,
          message: '',
          errorCode: LoginErrorCode.InvalidRole,
        };
      }

      // If login is successful, then fetch user data from API
      const { data: userData } = await fetchMyData();

      setUser({
        id: userData?.id,
        username: result.username,
        email: userData?.email ?? '',
        givenName: userData?.first_name ?? '',
        familyName: userData?.last_name ?? '',
        company: {
          id: userData?.company.id ?? 0,
          company_id: userData?.company.company_id ?? '', // eslint-disable-line camelcase
          company_name: userData?.company.company_name ?? '', // eslint-disable-line camelcase
          edcConfigurationStatus: userData?.company?.edc_configuration_status,
          license: userData?.company?.license || 'basic',
        },
        role: result?.attributes?.['custom:role'],
      });

      setIsAuthenticated(true);

      return { success: true, message: result?.challengeName ?? '' };
    } catch (error) {
      // Default error message
      let message = t('authPage.loginForm.errorMessage');
      let errorCode = LoginErrorCode.InvalidCredentials;

      // Check error cause, if user is disabled, then show user disabled message
      if ((error as Error).message === CognitoErrorMessage.UserDisabledException) {
        message = t('authPage.loginForm.userDisabledMessage');
        errorCode = LoginErrorCode.UserDisabled;
      }

      return {
        success: false,
        message: message,
        errorCode: errorCode,
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();

      setUser(null);
      setIsAuthenticated(false);
      queryClient.invalidateQueries(['user', 'me']);

      // Clear React Query cache
      queryClient.clear();

      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: t('authPage.loginForm.logoutFail'),
      };
    }
  };

  const completeNewPassword = async (
    username: string,
    oldPassowrd: string,
    newPassword: string,
  ) => {
    try {
      const loginResult = await Auth.signIn(username, oldPassowrd);

      if (loginResult?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const result = await Auth.completeNewPassword(loginResult, newPassword);

        // If activation is successful, then fetch user data from API
        const { data: userData } = await fetchMyData();

        setUser({
          id: userData?.id,
          username: result.username,
          email: userData?.email ?? '',
          givenName: userData?.first_name ?? '',
          familyName: userData?.last_name ?? '',
          role: result?.challengeParam?.userAttributes?.['custom:role'],
          company: {
            id: userData?.company.id ?? 0,
            company_id: userData?.company.company_id ?? '', // eslint-disable-line camelcase
            company_name: userData?.company.company_name ?? '', // eslint-disable-line camelcase
            edcConfigurationStatus: userData?.company?.edc_configuration_status,
            license: userData?.company?.license || 'basic',
          },
        });

        setIsAuthenticated(true);
      }

      return { success: true, message: '' };
    } catch (error) {
      const mappedMessage =
        CognitoErrorCode[(error as Error).name as keyof typeof CognitoErrorCode];

      const errorMessage = (error as Error).message;

      return {
        success: false,
        message: mappedMessage ?? errorMessage,
      };
    }
  };

  const sendVerificationCode = async (username: string) => {
    try {
      await Auth.forgotPassword(username);

      return {
        success: true,
        message: t('authPage.loginForm.SendCodeSuccessfully'),
      };
    } catch (error) {
      // Default error message
      let message = t('authPage.forgotPassword.errorMessage');

      if ((error as Error).message === CognitoErrorMessage.UserDisabledException) {
        message = t('authPage.forgotPassword.userDisabledMessage');
      } else if ((error as Error).message === CognitoErrorMessage.UserNotFoundException) {
        message = t('authPage.forgotPassword.userNotFound');
      }

      return {
        success: false,
        message: message,
      };
    }
  };

  const confirmPassword = async (username: string, confirmCode: string, newPassword: string) => {
    try {
      await Auth.forgotPasswordSubmit(username, confirmCode, newPassword);

      return {
        success: true,
        message: t('authPage.loginForm.passwordResetSuccessfully'),
      };
    } catch (error) {
      const mappedMessage =
        CognitoErrorCode[(error as Error).name as keyof typeof CognitoErrorCode];
      const errorMessage = (error as Error).message;

      return {
        success: false,
        message: mappedMessage || errorMessage,
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    user,
    signIn,
    signOut,
    completeNewPassword,
    sendVerificationCode,
    confirmPassword,
  };
};
