import { ColumnSortEvent } from 'primereact/column';

import { IComponent } from '../interfaces/IComponent';

export const requestRegionSortFunction = (event: ColumnSortEvent): IComponent[] => {
  const data = [...event.data];

  const eventOrder = event.order || 1;

  data.sort((data1, data2) => {
    const region1 = data1[event.field];
    const region2 = data2[event.field];

    let result = null;

    if (region1 == null && region2 == null) result = 0;
    else if (region1 == null && region2 != null) result = -1;
    else if (region1 != null && region2 == null) result = 1;
    else if (region1 < region2) result = -1;
    else if (region1 > region2) result = 1;
    else result = 0;

    return eventOrder * result;
  });

  return data;
};
