import {
  SUFFIX_CARBON_CONTENT_NUMERATOR,
  SUFFIX_EMISSION_NUMERATOR,
} from '../constants/products.constant';
import { DeclaredUnit, DeclaredUnitToSymbolsMap } from '../interfaces/IProductDetails';

// This function returns the 'declared unit' as a full string
// Takes amount and declared unit as parameters
// Example:
//    getFullDeclaredUnitText(123, 'kilogram') => '123 kilogram (kg)'
export const getFullDeclaredUnitText = (
  amount?: number,
  declaredUnit?: DeclaredUnit,
): JSX.Element => {
  if (amount == null || !declaredUnit) {
    return <span>N/A</span>;
  }

  return (
    <span>
      {amount} {declaredUnit} {DeclaredUnitToSymbolsMap[declaredUnit]}
    </span>
  );
};

// This function returns the 'carbon content' as a full string
// Takes amount and declared unit as parameters
// Example:
//    getFullCarbonContentText(123, 'kilogram') => '123 kg/kilogram (kg)'
export const getFullCarbonContentText = (
  amount?: number,
  declaredUnit?: DeclaredUnit,
): JSX.Element => {
  if (amount == null || !declaredUnit) {
    return <span>N/A</span>;
  }

  return (
    <div>
      <span className='font-bold'>{amount}</span> {SUFFIX_CARBON_CONTENT_NUMERATOR}/{declaredUnit}{' '}
      {DeclaredUnitToSymbolsMap[declaredUnit]}
    </div>
  );
};

// This function returns the 'carbon content' as a full string
// Takes amount and declared unit as parameters
// Example:
//    getFullEmissionText(123, 'kilogram') => '123 kg CO₂e/kilogram (kg)'
export const getFullEmissionText = (amount?: number, declaredUnit?: DeclaredUnit): JSX.Element => {
  if (amount == null || !declaredUnit) {
    return <span>N/A</span>;
  }

  return (
    <div>
      <span className='font-bold'>{amount}</span> {SUFFIX_EMISSION_NUMERATOR}/{declaredUnit}{' '}
      {DeclaredUnitToSymbolsMap[declaredUnit]}
    </div>
  );
};
