import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

type CreatePcfButtonProps = {
  disabled?: boolean;
};

const CreatePcfButton = ({ disabled }: CreatePcfButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const onClick = () => {
    // Open Create PCF Dialog
  };

  return (
    <Button
      className='max-w-fit flex'
      disabled={disabled}
      label={t('productDetailsPage.tabs.pcfTab.buttons.createPcfButton')}
      onClick={onClick}
    />
  );
};

export default CreatePcfButton;
