import { useTranslation } from 'react-i18next';

import ProductsTable from './components/products-table/ProductsTable';

const ProductsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={'px-3 py-5 sm:p-5 flex flex-column'}>
      <h1 className='text-2xl md:text-4xl'>{t('productsPage.heading')}</h1>

      <ProductsTable />
    </div>
  );
};

export default ProductsPage;
