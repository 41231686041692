/* eslint-disable camelcase */
import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EmissionUnit from '../../../../../../../components/emission-unit/EmissionUnit';
import { Locale } from '../../../../../../../contexts/language/LanguageContext';
import { parseDateWithoutTime } from '../../../../../../../shared/helpers/parse-date';
import {
  FootPrintStatus,
  IFootPrint,
  IFootPrintDetails,
} from '../../../../../../../shared/interfaces/IFootprint';
import { DeclaredUnit } from '../../../../../../../shared/interfaces/IProductDetails';
import { productsTablePcfColumnSortFunction } from '../../../../../../products/helpers/tableColumnSortFunctions';
import CreatePcfButton from '../create-pcf-button/CreatePcfButton';
import style from './PcfTable.module.css';

export type FootprintDetailsTabProps = {
  productId: number;
  footprints: IFootPrint[];
  isLoading: boolean;
};

const PcfTable = ({ footprints, isLoading, productId }: FootprintDetailsTabProps): JSX.Element => {
  const navigate = useNavigate();

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const pcfIdTemplate = (rowData: IFootPrint): JSX.Element | string => {
    const onPCFIdClick = () => {
      const href = `/products/${productId}/pcf/footprint/${rowData.id}`;
      navigate(href);
    };

    return (
      <div className='flex flex-column justify-content-center'>
        <div
          onClick={onPCFIdClick}
          className='font-normal max-w-fit hover:underline cursor-pointer'
        >
          {rowData.id}
        </div>
      </div>
    );
  };

  const pcfTemplate = (rowData: IFootPrint): JSX.Element | string => {
    const emissionValue = rowData?.pcf_excluding_biogenic || 0;

    if (!emissionValue) {
      return 'N/A';
    }

    return (
      <EmissionUnit
        value={emissionValue}
        unit={rowData.declared_unit.replace(/_/g, ' ') as DeclaredUnit}
      />
    );
  };

  const dateCreatedTemplate = (rowData: IFootPrintDetails): React.ReactNode => {
    return parseDateWithoutTime(rowData.date_created ?? '', language as Locale).toLocaleString();
  };

  const statusTemplate = (rowData: IFootPrintDetails): React.ReactNode => {
    return rowData.status;
  };

  const sortPcfValueColumn = (event: ColumnSortEvent) => {
    event.data = event.data.map((value: IFootPrint) => {
      return {
        ...value,
        pcf: value,
      };
    });

    return productsTablePcfColumnSortFunction(event);
  };

  const hasDraftFootprint = useMemo(() => {
    return footprints.some((footprint) => {
      return footprint.status === FootPrintStatus.DRAFT;
    });
  }, [footprints]);

  return (
    <div>
      <DataTable
        value={footprints}
        loading={isLoading}
        style={style}
        selectionMode='single'
        sortField='status'
        sortOrder={1}
        scrollable
        scrollHeight='600px'
      >
        <Column
          header={t('productDetailsPage.tabs.pcfTab.pcfTableColumns.pcfId')}
          field='id'
          body={pcfIdTemplate}
          style={{ width: '30rem' }}
        />
        <Column
          field='date_created'
          body={dateCreatedTemplate}
          header={t('productDetailsPage.tabs.pcfTab.pcfTableColumns.dateCreated')}
          sortable
          sortField='date_created'
          style={{ width: '20rem' }}
        />
        <Column
          header={t('productDetailsPage.tabs.pcfTab.pcfTableColumns.status')}
          body={statusTemplate}
          sortable
          field='status'
          sortField='status'
          style={{ width: '20rem' }}
        />
        <Column
          header={t('productDetailsPage.tabs.pcfTab.pcfTableColumns.pcfValue')}
          body={pcfTemplate}
          sortable
          field='pcf_excluding_biogenic'
          sortFunction={sortPcfValueColumn}
          style={{ width: '20rem' }}
        />
      </DataTable>
      <div className='mt-3'>
        <CreatePcfButton disabled={hasDraftFootprint} />
      </div>
    </div>
  );
};

export default PcfTable;
