import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../hooks/auth/useAuth';
import useDialogContext from '../../../hooks/dialog/useDialogContext';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { IComponent } from '../../../shared/interfaces/IComponent';

type ExportOrRequestPcfsButtonsProps = {
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
  componentsToShowPendingDuringBulk: IComponent[];
  setComponentsToShowPendingDuringBulk: (products: IComponent[]) => void;
};

const ExportOrRequestPcfsButtons = ({
  biogenicEmissionsMode,
  componentsToShowPendingDuringBulk,
  setComponentsToShowPendingDuringBulk,
}: ExportOrRequestPcfsButtonsProps): JSX.Element | null => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  if (user?.company?.license === 'basic') {
    return null;
  }

  const onClickExportPcfs = () => {
    openDialog('export-pcfs-dialog', { biogenicEmissionsMode });
  };

  const onClickRequestPcfs = () => {
    openDialog('bulk-request-pcfs-dialog', {
      componentsToShowPendingDuringBulk,
      setComponentsToShowPendingDuringBulk,
    });
  };

  return (
    <div className='flex flex-column md:flex-row w-full justify-content-end gap-2 mb-3'>
      <Button
        className='justify-content-center'
        outlined
        onClick={onClickExportPcfs}
        label={t('componentsPage.bulkPcfExport')}
      />
      <Button
        className='justify-content-center'
        onClick={onClickRequestPcfs}
        label={t('componentsPage.bulkPcfRequest')}
      />
    </div>
  );
};

export default ExportOrRequestPcfsButtons;
