type ComponentIdProps = {
  componentId?: string;
  ownProductId?: string;
};

export default function ComponentId({
  componentId,
  ownProductId,
}: ComponentIdProps): JSX.Element | null {
  if (!componentId) {
    return null;
  }

  const secondaryComponentId = ownProductId ?? null;

  return (
    <div className='flex flex-column justify-content-center'>
      <div className='font-normal'>{componentId}</div>
      {secondaryComponentId && (
        <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600'>
          {secondaryComponentId}
        </div>
      )}
    </div>
  );
}
