/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IComponent } from '../../shared/interfaces/IComponent';
import { IComponentDetails } from '../../shared/interfaces/IProductDetails';

export type ComponentsParams = {
  status?: string;
  region?: string;
  od?: string;
  search?: string;
};

export type UpdateProductOwnDataMutationParams = {
  componentId: string;
  ownProductId?: string;
  ownProductName?: string;
};

export type UpdateProductOwnDataInput = {
  own_product_name?: string;
  own_product_id?: string;
};

type ExportPcfsProps = {
  componentIds: number[];
};

export type ExportPcfsResponse = {
  fileUrl: string;
  successCount: number;
  errorCount: number;
};

type RequestSustainableAlternativeProps = {
  component_id: number;
};

type BulkRequestPcfsProps = {
  componentIds: number[];
};

type BulkRequestPcfsResponse =
  | {
      successCount?: number;
      failureCount?: number;
    }
  | string;

const amplifyApiService = new AmplifyApiService();

export const useListComponents = (
  params: ComponentsParams = {},
  options: UseQueryOptions<IComponent[], Error> = {},
): UseQueryResult<IComponent[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'component',
    init: {
      queryStringParameters: params,
    },
  };

  const query = useQuery<IComponent[], Error>({
    queryKey: ['listComponents', params],
    queryFn: async (): Promise<IComponent[]> => {
      const response = await amplifyApiService.get<{ components: IComponent[] }>({
        config: amplifyApiConfig,
      });

      return response.components;
    },
    ...options,
  });

  return query;
};

export const useGetComponentDetails = (
  componentId: string,
  options: UseQueryOptions<IComponentDetails, Error> = {},
): UseQueryResult<IComponentDetails, Error> => {
  const amplifyApiConfigForJsonDownloadLink: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `component/${componentId}`,
    init: {},
  };

  const query = useQuery<IComponentDetails, Error>({
    queryKey: ['getComponentDetails', componentId],
    queryFn: async (): Promise<IComponentDetails> => {
      // First get JSON download link from PACIFIC API
      const response = await amplifyApiService.get<IComponentDetails>({
        config: amplifyApiConfigForJsonDownloadLink,
      });

      return response;
    },
    ...options,
  });

  return query;
};

export const useUpdateComponentOwnData = (
  options: UseMutationOptions<void, Error, UpdateProductOwnDataMutationParams> = {},
): UseMutationResult<void, Error, UpdateProductOwnDataMutationParams> => {
  const mutation = useMutation<void, Error, UpdateProductOwnDataMutationParams>({
    mutationFn: async ({
      componentId,
      ownProductId,
      ownProductName,
    }: UpdateProductOwnDataMutationParams): Promise<void> => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `company-component/${componentId}`,
        init: {},
      };

      await amplifyApiService.put<UpdateProductOwnDataInput, void>({
        config: amplifyApiConfig,
        body: { own_product_id: ownProductId, own_product_name: ownProductName },
      });

      return;
    },

    ...options,
  });

  return mutation;
};

export const useExportPcfs = (
  options: UseMutationOptions<ExportPcfsResponse, Error, ExportPcfsProps> = {},
): UseMutationResult<ExportPcfsResponse, Error, ExportPcfsProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    init: {
      queryStringParameters: {
        action: 'download-csv',
      },
    },
  };

  const mutation = useMutation<ExportPcfsResponse, Error, ExportPcfsProps>({
    mutationFn: async (componentIds: ExportPcfsProps) => {
      return await amplifyApiService.post<ExportPcfsProps, ExportPcfsResponse>({
        config: amplifyApiConfig,
        body: componentIds,
      });
    },
    ...options,
  });

  return mutation;
};

export const useRequestSustainableAlternative = (
  options: UseMutationOptions<void, Error, RequestSustainableAlternativeProps> = {},
): UseMutationResult<void, Error, RequestSustainableAlternativeProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request/',
    init: {
      queryStringParameters: {
        action: 'request-sustainable-alternative',
      },
    },
  };

  const mutation = useMutation<void, Error, RequestSustainableAlternativeProps>({
    mutationFn: async ({ component_id }: RequestSustainableAlternativeProps) => {
      await amplifyApiService.post<RequestSustainableAlternativeProps, void>({
        config: amplifyApiConfig,
        body: { component_id },
      });
    },
    ...options,
  });

  return mutation;
};

export const useBulkRequestPcfs = (
  options: UseMutationOptions<BulkRequestPcfsResponse, Error, BulkRequestPcfsProps> = {},
): UseMutationResult<BulkRequestPcfsResponse, Error, BulkRequestPcfsProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    init: {
      queryStringParameters: {
        action: 'request-bulk-pcf',
      },
    },
  };

  const mutation = useMutation<BulkRequestPcfsResponse, Error, BulkRequestPcfsProps>({
    mutationFn: async (componentIds: BulkRequestPcfsProps) => {
      return await amplifyApiService.post<BulkRequestPcfsProps, BulkRequestPcfsResponse>({
        config: amplifyApiConfig,
        body: componentIds,
      });
    },
    ...options,
  });

  return mutation;
};
