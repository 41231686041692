import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IProduct } from '../../shared/interfaces/IProduct';

export type GetProductsProps = {
  country?: string;
  region?: string;
  search?: string;
};

type AddProductProps = {
  product_id: string;
  product_name: string;
  region: string;
  country?: string;
  description?: string;
};

type AddProductResponse = {
  id: number;
};

const amplifyApiService = new AmplifyApiService();

export const useGetProductById = (
  productId: string,
  options: UseQueryOptions<IProduct, Error> = {},
): UseQueryResult<IProduct, Error> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `product/${productId}`,
    init: {},
  };

  const query = useQuery<IProduct, Error>({
    queryKey: ['product', productId],
    queryFn: async (): Promise<IProduct> => {
      const response = await amplifyApiService.get<IProduct>({
        config: amplifyApiConfig,
      });

      return response;
    },
    ...options,
  });

  return query;
};

export const useGetProducts = (
  filters: GetProductsProps = {},
  options: UseQueryOptions<IProduct[], Error> = {},
): UseQueryResult<IProduct[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'product',
    init: {
      queryStringParameters: filters,
    },
  };

  const query = useQuery<IProduct[], Error>({
    queryKey: ['products', filters],
    queryFn: async (): Promise<IProduct[]> => {
      const response = await amplifyApiService.get<IProduct[]>({
        config: amplifyApiConfig,
      });

      return response;
    },
    ...options,
  });

  return query;
};

export const useAddProduct = (
  options: UseMutationOptions<AddProductResponse, Error, AddProductProps> = {},
): UseMutationResult<AddProductResponse, Error, AddProductProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'product',
    init: {},
  };

  const mutation = useMutation<AddProductResponse, Error, AddProductProps>({
    mutationFn: async (body: AddProductProps) => {
      return await amplifyApiService.post<AddProductProps, AddProductResponse>({
        config: amplifyApiConfig,
        body,
      });
    },
    ...options,
  });

  return mutation;
};
