import { ButtonProps } from 'primereact/button';
import { IconOptions } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import ComponentsFilledIcon from '../../../../../assets/icons/ComponentsFilled';
import ComponentsOutlineIcon from '../../../../../assets/icons/ComponentsOutline';
import ProductsFilledIcon from '../../../../../assets/icons/ProductsFilled';
import ProductsOutlineIcon from '../../../../../assets/icons/ProductsOutline';
import RequestsFilledIcon from '../../../../../assets/icons/RequestsFilled';
import RequestsOutlineIcon from '../../../../../assets/icons/RequestsOutline';
import MobileHeaderLink from '../header-link/MobileHeaderLink';

const MobileMenuOverlay = () => {
  const renderFilledIcon = (
    Icon: React.FC<IconOptions<ButtonProps, unknown>>,
    options: IconOptions<ButtonProps, unknown>,
  ) => {
    const newProps = { ...options, fillColor: '#b3cb2d' };

    return <Icon {...newProps} />;
  };

  const { t } = useTranslation();

  return (
    <>
      <MobileHeaderLink
        href='/products'
        label={t('global.productMenu')}
        icon={ProductsOutlineIcon}
        activeIcon={(options) => {
          return renderFilledIcon(ProductsFilledIcon, options);
        }}
      />
      <MobileHeaderLink
        href='/components'
        label={t('global.componentsMenu')}
        icon={ComponentsOutlineIcon}
        activeIcon={(options) => {
          return renderFilledIcon(ComponentsFilledIcon, options);
        }}
      />
      <MobileHeaderLink
        href='/requests'
        label={t('global.requestMenu')}
        icon={RequestsOutlineIcon}
        activeIcon={(options) => {
          return renderFilledIcon(RequestsFilledIcon, options);
        }}
      />
    </>
  );
};

export default MobileMenuOverlay;
