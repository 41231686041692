import { TreeNode } from 'primereact/treenode';
import { TreeSelect, TreeSelectChangeEvent, TreeSelectExpandedEvent } from 'primereact/treeselect';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import countries from '../../../../assets/static/countries.json';
import {
  CountryCodeToLabelMap,
  extractFullyCheckedNodes,
  findNodeByKey,
  RegionCodeToLabelMap,
} from '../../../../shared/helpers/country-region-helpers';
import { prepareFlagIconsRecursively } from '../../../../shared/helpers/prepare-flag-icons-recursively';
import { IProductFilters } from '../../interfaces/IProductFilters';
import styles from './LocationFilter.module.css';

type LocationFilterProps = {
  productFilters: IProductFilters;
  updateProductFilters: (filters: IProductFilters) => void;
};

const LocationFilter = (props: LocationFilterProps): JSX.Element => {
  const { productFilters, updateProductFilters } = props; // eslint-disable-line
  const { t } = useTranslation();
  const [selectedKeys, setSelectedKeys] = useState<{
    [key: string]: { checked: boolean; partiallyChecked: boolean };
  }>({});

  const [expandedKeys, setExpandedKeys] = useState({});
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    prepareFlagIconsRecursively(countries);
  }, [countries]);

  // Function to prepare initial selected nodes based on the query string params
  // e.g. if you directly visit https://..../products?country=DE,FR&region=global,sub_saharan_africa
  // then you should see the selected nodes as Germany, France, Global, Sub-Saharan Africa and all sub-countries under those regions
  const prepareInitialNodeKeys = (): void => {
    // Get the array of country keys and region keys from parent, i.e. the URL
    const countryKeys = productFilters.countryFilter; // e.g. ['DE', 'FR']
    const regionKeys = productFilters.regionFilter; // e.g. ['global', 'sub_saharan_africa']

    // Map to store the selected nodes to be set in the state, to show them selected in the tree
    const preSelectedNodeKeys: { [key: string]: { checked: boolean; partiallyChecked: boolean } } =
      {};

    // Fill the map with countries
    countryKeys.forEach((countryKey) => {
      preSelectedNodeKeys[countryKey] = { checked: true, partiallyChecked: false };
    });

    // Fill the map with regions
    // Note: If a region is seleceted, that means all the countries/sub-regions in that region must be selected as well
    regionKeys.forEach((regionKey) => {
      const regionNode = findNodeByKey(countries, regionKey);

      // Select the region itself first
      preSelectedNodeKeys[regionKey] = { checked: true, partiallyChecked: false };

      // Then select all the countries/sub-regions in that region
      const childrenNodes = regionNode?.children || [];

      childrenNodes.forEach((childNode) => {
        if (!childNode.children) {
          // If this child is a country, select it
          preSelectedNodeKeys[childNode.key as string] = { checked: true, partiallyChecked: false };
        } else {
          // Otherwise, it's a sub-region, so select all the countries in that sub-region
          childNode.children.forEach((subRegionChildNode) => {
            preSelectedNodeKeys[subRegionChildNode.key as string] = {
              checked: true,
              partiallyChecked: false,
            };
          });

          // Also don't forget to select the sub-region itself
          preSelectedNodeKeys[childNode.key as string] = { checked: true, partiallyChecked: false };
        }
      });
    });

    // Now higlight those nodes in the tree
    setSelectedKeys(preSelectedNodeKeys);
  };

  // Function to handle when you make a change in the selections in the tree
  const onChange = (e: TreeSelectChangeEvent) => {
    const selectedKeysMap =
      (e.value as { [key: string]: { checked: boolean; partiallyChecked: boolean } }) || {}; // e.g. { DE: true, FR: true, global: true, europe: true }
    const fullyCheckedNodeKeysMap = extractFullyCheckedNodes(selectedKeysMap);

    setSelectedKeys(selectedKeysMap);

    const countryFilter: string[] = []; // e.g. ['DE', 'FR']
    const regionFilter: string[] = []; // e.g. ['global', 'sub_saharan_africa']

    // Differentiate countries and regions based on the keys
    // This step is necessary because we can't tell if a key belongs to a country or region just by looking at it
    Object.keys(fullyCheckedNodeKeysMap).forEach((key) => {
      if (CountryCodeToLabelMap[key]) {
        // If it's a country
        countryFilter.push(key);
      }

      if (RegionCodeToLabelMap[key]) {
        // If it's a region
        regionFilter.push(key);
      }
    });

    // Update the filter values in the parent component, which will eventually update the URL
    updateProductFilters({
      ...productFilters,
      countryFilter: countryFilter,
      regionFilter: regionFilter,
    });
  };

  // Function to handle when you expand a node in the tree
  // This is necessary to keep track of the expanded nodes
  // Also, because of a bug in the TreeSelect component, if you don't handle this, some other random nodes can be toggled when you expand an irrelevant node
  const onToggle = (e: TreeSelectExpandedEvent) => {
    setExpandedKeys(e.value);
  };

  // Based on the filter values that initially come from the parent component (from URL actually), set the selected nodes
  useEffect(() => {
    prepareInitialNodeKeys();
  }, [productFilters]);

  const valueTemplate = (nodes: TreeNode[] | TreeNode) => {
    // If notring is selected, show the placeholder
    if (!(nodes as TreeNode[]).length) {
      return <span>{t('productsPage.filters.locationFilter.placeholder')}</span>;
    }

    // If there are more than 1 selected nodes, display the number of selected nodes
    if ((nodes as TreeNode[]).length > 1) {
      return (
        <div className='p-'>
          <div className='flex flew-row gap-3'>
            <span className='text-primary'>Location: </span>
            <span>
              {t('productsPage.filters.locationFilter.itemsSelected', {
                count: (nodes as TreeNode[]).length,
              })}
            </span>
          </div>
        </div>
      );
    }

    // Otherwise show their labels with icons
    return (
      <div className='gap-3 overflow-hidden flex flex-row'>
        <span className='text-primary'>Location: </span>
        <span className='inline overflow-hidden text-overflow-ellipsis inline'>
          {(nodes as TreeNode[])[0].label}
        </span>
      </div>
    );
  };

  return (
    <TreeSelect
      value={selectedKeys}
      options={countries}
      selectionMode='checkbox'
      valueTemplate={valueTemplate}
      onChange={onChange}
      expandedKeys={expandedKeys}
      onToggle={onToggle}
      onHide={() => {
        setIsOverlayVisible(false);
      }}
      onShow={() => {
        setIsOverlayVisible(true);
      }}
      showClear
      filter
      filterInputAutoFocus
      className={classNames(
        isOverlayVisible ? styles['location-filter-open'] : styles['location-filter-close'],
      )}
      pt={{
        root: {
          className: styles['root'],
        },
        wrapper: {
          className: styles['overlay-wrapper'],
        },
        labelContainer: {
          className: 'max-h-full',
        },
        label: {
          className: styles['label'],
        },
        closeButton: {
          className: 'hidden',
        },
        clearIcon: {
          className: classNames(
            'm-0 static',
            {
              hidden: selectedKeys === undefined || Object.keys(selectedKeys).length === 0,
            },
            styles['clear-icon'],
          ),
        },
      }}
    />
  );
};

export default LocationFilter;
