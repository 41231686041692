import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import InfoIconPrimary from '../../assets/icons/InfoIconPrimary';
import EmptyComponentsImg from '../../assets/images/empty-components.svg';
import CustomSpinner from '../../components/custom-spinner/CustomSpinner';
import DownloadJsonFileButton from '../../components/download-pcf/DownloadJsonFileButton';
import DownloadPdfFileButton from '../../components/download-pcf/DownloadPdfFileButton';
import EmptyScreen from '../../components/empty-screen/EmptyScreen';
import RequestStatus from '../../components/request-status/RequestStatus';
import WebsocketContext, {
  IWebsocketMessage,
  WebsocketEventType,
} from '../../contexts/websocket/WebsocketContext';
import { ComponentsParams, useListComponents } from '../../hooks/api/components';
import { useCreatePcfRequest } from '../../hooks/api/request-pcf';
import useDialogContext from '../../hooks/dialog/useDialogContext';
import useNotificationContext from '../../hooks/notification/useNotificationContext';
import ComponentRegion from '../../shared/components/component-region/ComponentRegion';
import { PCFBiogenicEmissionsMode } from '../../shared/enums/pcf';
import { PCFRequestStatus } from '../../shared/enums/pcf-request';
import { ToastSeverity } from '../../shared/enums/toast-severity';
import { requestRegionSortFunction } from '../../shared/helpers/requestRegionSortFunction';
import { IComponent } from '../../shared/interfaces/IComponent';
import ComponentId from './components/ComponentId';
import ComponentName from './components/ComponentName';
import ComponentPcfValue from './components/ComponentPcfValue';
import ComponentsHeader from './components/ComponentsHeader';
import styles from './ComponentsPage.module.css';
import { IComponentFilters } from './interfaces/IComponentFilters';
import { initialProductsListRequest } from './toast/request-toast';

const ComponentsPage = () => {
  // Search params (filters) from the URL
  const [searchParams, setSearchParams] = useSearchParams();

  const [biogenicEmissionsMode, setBiogenicEmissionsMode] = useState<PCFBiogenicEmissionsMode>(
    PCFBiogenicEmissionsMode.Include,
  );

  // Parameters used for filtering when fetching components
  const [componentFilters, setComponentFilters] = useState<IComponentFilters>({
    statusFilter: searchParams.get('status')?.split(',') || [],
    locationsFilter: searchParams.get('region')?.split(',') || [],
    searchStr: searchParams.get('search') || '',
  });

  // For requesting pcf for components with region, to disable the Request Pcf button for the requested component
  // It is a map of product_id to boolean, where boolean is true if the component is requested
  // So that you can request pcf for multiple components at the same time (USING BUTTONS IN EACH ROW)
  const [selectedComponentsToRequestPcf, setSelectedComponentsToRequestPcf] = useState<
    Record<string, boolean>
  >({});

  // For bulk pcf requesting, to apply Optimistic UI strategy, to show those components as "Pending" in the table
  // Without them actually being "Pending" in the database
  // Those components will be declared in the BulkRequestPcfsDialog
  const [componentsToShowPendingDuringBulk, setComponentsToShowPendingDuringBulk] = useState<
    IComponent[]
  >([]);

  // For exporting selected components to csv

  const { notify } = useNotificationContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { openDialog } = useDialogContext();

  const { useRegisterCallback } = useContext(WebsocketContext);

  const { isLoading: loading, mutate: createInitialProductsListRequest } = useCreatePcfRequest({
    onSuccess: () => {
      const notifyData = initialProductsListRequest(
        ToastSeverity.INFO,
        t('DetailsContent.requestSubmitted'),
        t('DetailsContent.yourRequestWillBeReviewedAsSoonAsPossible'),
        t('DetailsContent.checkBackPeriodicallyToSeeIfYourProductsAreAdded'),
      );
      notify(notifyData);
    },
    onError: () => {
      const notifyData = initialProductsListRequest(
        ToastSeverity.ERROR,
        t('DetailsContent.failedToSubmitRequest'),
        t('DetailsContent.somethingWentWrong'),
        t('DetailsContent.pleaseTryAgainLater'),
      );
      notify(notifyData);
    },
  });

  /* eslint-disable camelcase */
  const { mutate: createPcfRequest } = useCreatePcfRequest({
    onMutate: (variables) => {
      // Make the RequestPcf button disabled for the requested component
      setSelectedComponentsToRequestPcf((prev) => {
        return { ...prev, [variables.component_id as number]: true };
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        summary: t('DetailsContent.pcfRequestFailed'),
        detail: t('DetailsContent.pleaseTryAgainLater'),
        life: 5000,
      });
    },
    onSettled: (data, error, variables) => {
      setSelectedComponentsToRequestPcf((prev) => {
        // After half a second, set the component to false to enable the button again
        // Make the RequestPcf button enabled for the requested component at the end
        return { ...prev, [variables.component_id as number]: false };
      });
    },
  });
  /* eslint-enable camelcase */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Prepare the filter parameters for fetching components, utilizing memoization
  const filterParams = useMemo(() => {
    const paramsValue: ComponentsParams = {};
    if (componentFilters.statusFilter.length) {
      paramsValue['status'] = componentFilters.statusFilter.join(',');
    }

    if (componentFilters.locationsFilter.length) {
      paramsValue['region'] = componentFilters.locationsFilter.join(',');
    }

    if (componentFilters.searchStr) {
      paramsValue['search'] = componentFilters.searchStr.trim();
    }

    return paramsValue;
  }, [componentFilters]);

  // RQ Query to get components
  const {
    data: fetchedComponents,
    isLoading,
    isError,
    isSuccess,
    refetch: refetchComponents,
  } = useListComponents(filterParams);

  // State to store the fetched components (initially start with fetchedComponents, use cache if needed)
  const [components, setComponents] = useState<IComponent[]>(fetchedComponents || []);

  useEffect(() => {
    if (fetchedComponents && isSuccess) {
      setComponents(fetchedComponents);
    }
  }, [fetchedComponents, isSuccess]);

  // Listen to componentsToShowPendingDuringBulk changes
  // So that during their bulk-request process, we can show those selected components as "Pending" in the table
  useEffect(() => {
    if (componentsToShowPendingDuringBulk.length) {
      setComponents((prev) => {
        return prev.map((component) => {
          const optimisticallyUpdatedComponent = componentsToShowPendingDuringBulk.find((c) => {
            return c.product_id === component.product_id;
          });

          return optimisticallyUpdatedComponent ? optimisticallyUpdatedComponent : component;
        });
      });
    }
  }, [componentsToShowPendingDuringBulk]);

  // Listen to URL string parameter changes, and update the filter values
  useEffect(() => {
    setComponentFilters({
      statusFilter: searchParams.get('status')?.split(',') || [],
      locationsFilter: searchParams.get('region')?.split(',') || [],
      searchStr: searchParams.get('search')?.trim() || '',
    });
  }, [searchParams]);

  // Callback functions for handling websocket messages
  const handleMsgOfTypeRequestPCF = (message: IWebsocketMessage) => {
    refetchComponents();

    if (message.success === true) {
      notify({
        severity: ToastSeverity.SUCCESS,
        summary: t('toastMessages.pcfRequest.successSummary', {
          componentName: message.componentName,
        }),
        detail: t('toastMessages.pcfRequest.successDetail'),
        life: 5000,
      });
    } else if (message.success === false) {
      notify({
        severity: ToastSeverity.ERROR,
        summary: t('toastMessages.pcfRequest.errorSummary', {
          componentName: message.componentName,
        }),
        detail: t('toastMessages.pcfRequest.errorDetail'),
        life: 5000,
      });
    }
  };

  const handleMsgOfTypeRequestBulkPcfSingleCompleted = (message: IWebsocketMessage) => {
    const { success, component } = message;

    if (success && component) {
      setComponents((prev) => {
        return prev.map((c) => {
          if (c.product_id === component.product_id) {
            return component;
          }

          return c;
        });
      });
    }
  };

  const handleMsgOfTypeRequestBulkPcfSummary = (message: IWebsocketMessage) => {
    refetchComponents();

    // Depending on the success and error count, show toast messages
    const { successCount, failureCount } = message;

    // Show success and error toasts based on the response
    if (successCount) {
      notify({
        severity: ToastSeverity.SUCCESS,
        detail: t('toastMessages.bulkRequestPcfs.states.success.detail', { successCount }),
      });
    }

    if (failureCount) {
      notify({
        severity: ToastSeverity.ERROR,
        detail: t('toastMessages.bulkRequestPcfs.states.failure.detail', { failureCount }),
      });
    }
  };

  // Register the callback function for handling websocket messages
  useRegisterCallback(WebsocketEventType.RequestPCF, handleMsgOfTypeRequestPCF);
  useRegisterCallback(
    WebsocketEventType.RequestBulkPcfSingleCompleted,
    handleMsgOfTypeRequestBulkPcfSingleCompleted,
  );
  useRegisterCallback(
    WebsocketEventType.RequestBulkPcfSummary,
    handleMsgOfTypeRequestBulkPcfSummary,
  );

  // Function for updating the filter values in the URL
  const updateComponentFilters = (newFilters: IComponentFilters) => {
    let newSearchParams;

    if (newFilters.statusFilter.length) {
      newSearchParams = { status: newFilters.statusFilter.join(',') };
    }

    if (newFilters.locationsFilter.length) {
      newSearchParams = { ...newSearchParams, region: newFilters.locationsFilter.join(',') };
    }

    if (newFilters.searchStr) {
      newSearchParams = { ...newSearchParams, search: newFilters.searchStr };
    }

    // Update URL parameters
    setSearchParams(newSearchParams);
  };

  const handleTicketSubmission = () => {
    createInitialProductsListRequest({
      // eslint-disable-next-line camelcase
      request_type: 'initial_product_list',
    });
  };

  const onRequestPCFButtonClick = useCallback((component: IComponent) => {
    // If component has no region, show the Dialog
    if (!component.region) {
      openDialog('request-pcf-dialog', { component });
    } else {
      // If component already has a region, fetch PCF data without showing any Dialog and refresh components

      /* eslint-disable camelcase */
      createPcfRequest({
        component_id: component.product_id,
        region: component.region,
        request_type: 'pcf',
        file_type: ['pdf', 'json'],
      });
      /* eslint-enable camelcase */
    }
  }, []);

  if (isError) {
    return <p className='text-red-500'>{t('componentsPage.failedToGetComponents')}</p>;
  }

  const componentNameTemplate = (component: IComponent) => {
    const isClickable = [PCFRequestStatus.Complete, PCFRequestStatus.Updated].includes(
      component.status,
    );

    return (
      <ComponentName
        componentId={component.product_id}
        componentName={component.product_name}
        ownProductName={component.own_product_name}
        sustainableAlternativeAvailable={component.sustainable_alternative_available}
        href={isClickable ? `/components/${component.product_id}` : undefined}
      />
    );
  };

  const componentIdTemplate = (component: IComponent) => {
    return (
      <ComponentId componentId={component.product_cid} ownProductId={component.own_product_id} />
    );
  };

  const regionTemplate = (component: IComponent) => {
    return <ComponentRegion component={component} />;
  };

  const componentStatusTemplate = (component: IComponent) => {
    return <RequestStatus status={component.status} />;
  };

  const pcfsTemplate = (component: IComponent) => {
    return (
      <div className='flex'>
        {component.request_id && component.status === PCFRequestStatus.Complete && (
          <>
            <DownloadPdfFileButton requestId={component.request_id} />
            <DownloadJsonFileButton
              componentName={component.product_name}
              requestId={component.request_id}
            />
          </>
        )}
      </div>
    );
  };

  const requestPcfTemplate = (component: IComponent) => {
    // If the component is already requested, don't show the button
    if (component.request_id && !selectedComponentsToRequestPcf[component.product_id]) return;

    return (
      <Button
        className='w-full'
        label={t('componentsPage.requestPcf')}
        disabled={selectedComponentsToRequestPcf[component.product_id]}
        loading={selectedComponentsToRequestPcf[component.product_id]}
        onClick={() => {
          return onRequestPCFButtonClick(component);
        }}
      />
    );
  };

  const pcfValueTemplate = (component: IComponent) => {
    return (
      <ComponentPcfValue component={component} biogenicEmissionsMode={biogenicEmissionsMode} />
    );
  };

  // If fetched components are empty, show the empty screen
  if (
    !fetchedComponents?.length &&
    components?.length === 0 &&
    Object.keys(filterParams).length === 0 &&
    !isLoading
  ) {
    return (
      <EmptyScreen imageSrc={EmptyComponentsImg}>
        <h4>{t('emptyComponentsPage.heading')}</h4>
        <p>{t('emptyComponentsPage.description1')}</p>
        <div className='flex flex-wrap align-items-center'>
          <p className='m-0 flex flex-wrap align-items-center'>
            {t('emptyComponentsPage.description2')}
            <Button
              label={
                loading
                  ? t('emptyComponentsPage.submittingTicket')
                  : t('emptyComponentsPage.submitTicket')
              }
              onClick={handleTicketSubmission}
              className={classNames('text-primary underline p-0 ml-1', styles['submit-ticket'])}
              disabled={loading}
              text
            />
          </p>
          {loading && <CustomSpinner strokeWidth={8} className='w-1rem ml-2' />}
        </div>
      </EmptyScreen>
    );
  }

  return (
    <div className={classNames('px-3 py-5 sm:p-5 flex flex-column', styles['components-page'])}>
      <h1 className='text-2xl md:text-4xl'>
        <Trans i18nKey='componentsPage.heading' />
      </h1>

      <div className='flex flex-column gap-4'>
        <DataTable
          loading={isLoading}
          value={components}
          scrollable
          header={
            <ComponentsHeader
              componentFilters={componentFilters}
              updateComponentFilters={updateComponentFilters}
              biogenicEmissionsMode={biogenicEmissionsMode}
              setBiogenicEmissionsMode={setBiogenicEmissionsMode}
              componentsToShowPendingDuringBulk={componentsToShowPendingDuringBulk}
              setComponentsToShowPendingDuringBulk={setComponentsToShowPendingDuringBulk}
            />
          }
          pt={{
            wrapper: {
              className: styles['table-wrapper'],
            },
          }}
        >
          <Column
            field='product_name'
            sortable
            header={t('componentsPage.componentName')}
            style={{ minWidth: '8rem', maxWidth: '16rem' }}
            frozen
            body={componentNameTemplate}
          />
          <Column
            field='product_cid'
            sortable
            header={t('componentsPage.componentId')}
            style={{ minWidth: '8rem', maxWidth: '12rem' }}
            body={componentIdTemplate}
          />
          <Column
            field='region'
            sortable
            sortFunction={requestRegionSortFunction}
            header={t('componentsPage.location')}
            style={{ minWidth: '10rem' }}
            body={regionTemplate}
          />
          <Column
            field={
              biogenicEmissionsMode === PCFBiogenicEmissionsMode.Include
                ? 'pcf_including'
                : 'pcf_excluding'
            }
            sortable
            header={t('componentsPage.pcfValue')}
            body={pcfValueTemplate}
            style={{ minWidth: '8rem' }}
          />
          <Column
            header={
              <div className='flex gap-2'>
                {t('componentsPage.pcfs')}
                <Button
                  className='p-0 flex justify-content-start info-icon cursor-pointer'
                  link
                  icon={InfoIconPrimary}
                  onClick={() => {
                    openDialog('pcf-info-dialog');
                  }}
                />
              </div>
            }
            body={pcfsTemplate}
            style={{ minWidth: '6rem' }}
          />

          <Column
            header={t('componentsPage.status')}
            style={{ minWidth: '6rem' }}
            body={componentStatusTemplate}
          />
          <Column body={requestPcfTemplate} className='w-12rem' />
        </DataTable>
        <Button
          className='mr-auto mb-4 px-3'
          label={t('componentsPage.requestAdditionalComponent')}
          onClick={() => {
            openDialog('request-additional-component-dialog');
          }}
        />
      </div>
    </div>
  );
};

export default ComponentsPage;
