import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const ComponentsOutlineIcon = (options: IconOptions) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <g opacity='0.8' clipPath='url(#clip0_6347_66963)'>
        <path
          d='M1 1.81731C1 1.22815 1.47815 0.75 2.06731 0.75H7.75962C8.34877 0.75 8.82692 1.22815 8.82692 1.81731V5.375C8.82692 5.96415 8.34877 6.44231 7.75962 6.44231H2.06731C1.78424 6.44231 1.51277 6.32986 1.31261 6.1297C1.11245 5.92954 1 5.65807 1 5.375V1.81731ZM12.3846 3.24038C12.3846 2.65123 12.8628 2.17308 13.4519 2.17308H18.4327C19.0218 2.17308 19.5 2.65123 19.5 3.24038V11.0673C19.5 11.6565 19.0218 12.1346 18.4327 12.1346H13.4519C13.1689 12.1346 12.8974 12.0222 12.6972 11.822C12.4971 11.6218 12.3846 11.3504 12.3846 11.0673V3.24038ZM2.42308 10.3558C2.42308 9.76661 2.90123 9.28846 3.49038 9.28846H8.47115C9.06031 9.28846 9.53846 9.76661 9.53846 10.3558V12.4904C9.53846 13.0795 9.06031 13.5577 8.47115 13.5577H3.49038C3.20732 13.5577 2.93584 13.4452 2.73568 13.2451C2.53553 13.0449 2.42308 12.7735 2.42308 12.4904V10.3558Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default ComponentsOutlineIcon;
