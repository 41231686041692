import 'normalize.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/layout/prime-css/primereact.min.css';
import './assets/layout/prime-css/theme-pacific-light.css';
import './assets/layout/prime-css/layout-light.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import { LanguageProvider } from './contexts/language/LanguageProvider';
import { AuthProvider } from './hooks/auth/useAuth';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <LanguageProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </LanguageProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
