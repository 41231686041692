export enum ComponentRegion {
  AsiaPacific = 'Asia Pacific',
  SouthAmerica = 'South America',
  NorthAmerica = 'North America',
  Europe = 'Europe',
  Global = 'Global',
}

export const mapComponentRegionToKey = (componentRegion: ComponentRegion): string => {
  switch (componentRegion) {
    case ComponentRegion.AsiaPacific:
      return 'asiaPacific';
    case ComponentRegion.SouthAmerica:
      return 'southAmerica';
    case ComponentRegion.NorthAmerica:
      return 'northAmerica';
    case ComponentRegion.Europe:
      return 'europe';
    case ComponentRegion.Global:
      return 'global';
    default:
      return '';
  }
};
