import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import styles from './SearchBar.module.css';

type SearchBarProps = {
  initialValue?: string;
  onSearchChange: (searchStr: string) => void;
};

const SearchBar = ({ initialValue, onSearchChange }: SearchBarProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState(initialValue);

  const debouncedSearch = useDebouncedCallback(onSearchChange, 500);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <span className={classNames('p-fluid', styles['search-bar'])}>
      <span className={classNames('p-float-label p-input-icon-left h-full w-full')}>
        <i className='pi pi-search z-1' />
        <InputText id='search-bar' className='w-full h-full' value={search} onChange={onChange} />
        <label htmlFor='search-bar'>{t('global.typeToSearch')}</label>
      </span>
    </span>
  );
};

export default SearchBar;
