import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../../hooks/auth/useAuth';
import { parseProductIdFromPcfJson } from '../../../../../shared/helpers/parse-product-id-from-pcf-json';
import { IComponentDetails } from '../../../../../shared/interfaces/IProductDetails';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';
import InplaceEdit from '../../inplace-edit/InplaceEdit';

interface GeneralDetailsSectionProps extends ComponentProps<'div'> {
  componentDetails?: IComponentDetails;
  isLoading?: boolean;
}

const GeneralDetailsSection = (props: GeneralDetailsSectionProps): JSX.Element => {
  const { componentDetails, isLoading, ...rest } = props;

  const { t } = useTranslation();

  const { user } = useAuth();

  const secondaryProductId = componentDetails?.own_product_id ?? '';

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>{t('componentDetailsPage.sections.generalDetailsSection.generalDetails')}</h5>

      <InfoCardRow label={t('componentDetailsPage.sections.generalDetailsSection.yourId.label')}>
        <InplaceEdit
          fieldId='ownProductId'
          value={secondaryProductId}
          undefinedValue={t(
            'componentDetailsPage.sections.generalDetailsSection.yourId.undefinedValue',
          )}
          textClassname='text-md'
          disabled={user?.company?.license === 'basic'}
        />
      </InfoCardRow>

      <InfoCardRow label={t('componentDetailsPage.sections.generalDetailsSection.supplierId')}>
        {parseProductIdFromPcfJson(componentDetails?.productIds) || 'N/A'}
      </InfoCardRow>

      <InfoCardRow label={t('componentDetailsPage.sections.generalDetailsSection.supplier')}>
        <span>{componentDetails?.companyName}</span>
      </InfoCardRow>

      <InfoCardRow label={t('componentDetailsPage.sections.generalDetailsSection.location')}>
        <span>{componentDetails?.pcf.geographyRegionOrSubregion}</span>
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(GeneralDetailsSection);
