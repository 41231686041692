/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import { useGetFootprintById } from '../../hooks/api/footprint';

const FootprintDetailsPage = ({ footprintId }: { footprintId: string }): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // If footprint id not provided then return nothing
  if (!footprintId) {
    return <></>;
  }
  // Getting the footprint details
  const { data: footprintDetails, isLoading } = useGetFootprintById(footprintId);
  // If footprint details not found then navigate to 404 page
  if (!footprintDetails && !isLoading) {
    return <Navigate to='/404' />;
  }

  return (
    <div>
      <Button
        className='justify-content-center text-sm p-0 mt-2'
        text
        icon='pi pi-arrow-circle-left'
        onClick={() => {
          return navigate(-1);
        }}
        label={t('productDetailsPage.tabs.pcfTab.buttons.goBackButton')}
      />
      <h4>{t('productDetailsPage.tabs.pcfTab.footprintDetailsText')}</h4>
    </div>
  );
};

export default FootprintDetailsPage;
